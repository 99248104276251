/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { pt } from 'date-fns/locale';
import { format, parseISO, isAfter } from 'date-fns';

import { MdDeleteSweep, MdFileDownload, MdEmail } from 'react-icons/md';
import {
  FaEdit,
  FaBuffer,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
  FaClone,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { FiDownloadCloud, FiSlack } from 'react-icons/fi';
import { FcCustomerSupport } from 'react-icons/fc';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SeachIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Fab from '@material-ui/core/Fab';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {
  ptamLoad,
  ptamClear,
  ptamDelete,
  ptamStatus,
} from '../../store/modules/ptam/actions';

import { requesterLoad } from '../../store/modules/requester/actions';
import { procJudiLoad } from '../../store/modules/procJudi/actions';
import { terrenoLoad } from '../../store/modules/terreno/actions';
import { edificacaoLoad } from '../../store/modules/edificacao/actions';
import { ofertaLoad } from '../../store/modules/oferta/actions';
import { amostrasLoad } from '../../store/modules/amostras/actions';

import Concluido from '../../components/Status/Concluido';
import Pendente from '../../components/Status/Pendente';
import Expired from '../../components/Status/Expired';
import Individual from '../../components/Status/Individual';
import Assinatura from '../../components/Status/Assinatura';
import Mobile from '../../components/Status/Mobile';
import Loader from '../../components/Loader';
import DownloadLoader from '../../components/DownloadLoader';

import api from '../../services/api';
import history from '../../services/history';

import {
  Container,
  Header,
  BtnStyle,
  PageActions,
  SuportItems,
} from './styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dashboard() {
  const classes = useStyles();

  const user = useSelector(state => state.user.profile);
  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    solicitante: '',
    ptam: '',
  });
  const [loading, setLoading] = useState(true);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [ptams, setPtams] = useState([]);
  const [open, setOpen] = useState(false);
  const [cadastro, setCadastro] = useState(false);
  const [delet, setDelet] = useState({
    del: false,
    id: '',
  });
  const [relatorio, setRelatorio] = useState(false);
  const [sucess, setSucess] = useState(false);
  const delet_text =
    'Deseja realmente excluir este PTAM? Após esta ação seus dados não estarão mais disponíveis para consulta!';
  const [expired, setExpired] = useState({
    exp: false,
    id: '',
  });
  const [clone, setClone] = useState({
    cl: false,
    id: '',
  });
  const [clonado, setClonado] = useState({
    clone: false,
    id: '',
  });
  const [support, setSupport] = useState(false);
  const expired_text =
    'Este PTAM está expirado e não pode mais ser utilizado! PTAMS expirados serão automaticamente excluídos da sua lista em 30 dias!';

  const now = new Date();

  useEffect(() => {
    if (
      !user.mobile ||
      !user.cep ||
      !user.address ||
      !user.number ||
      !user.city ||
      !user.state
    ) {
      setCadastro(true);
    }
  }, [user.address, user.cep, user.city, user.mobile, user.number, user.state]);

  const loadPtams = async () => {
    const response = await api.get('ptams', {
      params: {
        per_page: 6,
        page,
      },
    });

    const data = response.data.map(ptam => ({
      ...ptam,
      emissao: format(parseISO(ptam.createdAt), "d 'de' MMMM 'de' yyyy", {
        locale: pt,
      }),
      expiracao: format(parseISO(ptam.expire_at), "d 'de' MMMM 'de' yyyy", {
        locale: pt,
      }),
      imagePtam: '',
      print: !!ptam.id_offer,
      PButton: !!ptam.link_download,
      actions: !(now.getTime() > parseISO(ptam.expire_at).getTime()),
      mobile: ptam.mobile_lock,
    }));

    setPtams(data);
    setRefresh(false);
    setLoading(false);
  };

  useEffect(() => {
    dispatch(ptamClear());

    loadPtams();
  }, [refresh, page, downloadLoader, dispatch]);

  function deletePtam(id) {
    dispatch(ptamDelete(id));
    setRefresh(true);
    setDelet({ del: false, id: '' });
  }

  function editPtam(ptam) {
    const { id_requester, id_judicial, id_land, id_building } = ptam;

    if (id_requester) {
      dispatch(requesterLoad(id_requester));
    }

    if (id_judicial) {
      dispatch(procJudiLoad(id_judicial));
    }

    if (id_land) {
      dispatch(terrenoLoad(id_land));
    }

    if (id_building) {
      dispatch(edificacaoLoad(id_building));
    }

    dispatch(ofertaLoad(ptam.id));
    dispatch(amostrasLoad(ptam.id));
    dispatch(ptamLoad(ptam));

    if (ptam.status) {
      dispatch(ptamStatus(ptam.id, false));
    }
  }

  function handlePage(action) {
    if (action === 'next') {
      setPage(page + 1);
    }

    if (action === 'back') {
      setPage(page - 1);
    }

    if (page < 0) {
      setPage(0);
    }
  }

  async function printPtam(id) {
    setDownloadLoader(true);

    await api.get(`makedir/${id}`);
    const response = await api.get(`print/${id}`);

    const linkS3 = response.data;

    const link = document.createElement('a');
    link.href = linkS3;
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);

    setDownloadLoader(false);
  }

  async function clonePtam(id) {
    setClone({ cl: false, id: '' });
    const result = await api.post(`clone/${id}`);

    if (result) {
      setClonado({ clone: true, id: result.data.ret.id });
      setRefresh(true);
    }
  }

  async function handleSearch() {
    setLoading(true);
    setPtams([]);

    const response = await api.get('ptam-search', {
      params: {
        solicitante: search.solicitante ? search.solicitante : '',
        ptam: search.ptam ? search.ptam : '',
      },
    });

    const data = response.data.map(ptam => ({
      ...ptam,
      emissao: format(parseISO(ptam.createdAt), "d 'de' MMMM 'de' yyyy", {
        locale: pt,
      }),
      expiracao: format(parseISO(ptam.expire_at), "d 'de' MMMM 'de' yyyy", {
        locale: pt,
      }),
      imagePtam: '',
      print: !!ptam.id_offer,
      PButton: !!ptam.link_download,
      actions: !(now.getTime() > parseISO(ptam.expire_at).getTime()),
      mobile: ptam.mobile_lock,
    }));

    setPtams(data);
    setLoading(false);
  }

  async function handleSignature() {
    setOpen(false);
    history.push('/signatures');
  }

  function handleCheckExpires(ptam) {
    const now = new Date().toISOString();
    if (ptam.expire_at < now) {
      return false;
    }
    return true;
  }

  /* função que valida as opções para acesso aos botões de edição do PTAM */
  const handleCheckAccess = ptam => {
    if (isAfter(parseISO(user.expire_at), new Date())) {
      return true;
    }

    if (
      isAfter(parseISO(user.expire_at), new Date()) &&
      user.ptam_quantity <= 0
    ) {
      return false;
    }

    if (ptam.mobile) {
      return false;
    }

    if (ptam.individual && ptam.link_download) {
      return false;
    }

    if (!ptam.individual && !isAfter(parseISO(user.expire_at), new Date())) {
      return false;
    }

    return true;
  };

  const handleCloneAccess = ptam => {
    if (user.expired || ptam.individual || ptam.mobile) {
      return false;
    }

    return true;
  };

  function handleClick(ptam) {
    if (handleCheckAccess(ptam)) {
      editPtam(ptam);
    }
  }

  function handleCadastro() {
    setCadastro(false);
    history.push('/profile');
  }

  return (
    <Container>
      {loading && <Loader />}
      {downloadLoader && <DownloadLoader />}
      <div>
        <Dialog
          open={cadastro}
          onClose={() => setCadastro(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Cadastro Incompleto!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Prezado(a) {user.name}, seu perfil está incompleto. Na tela a
              seguir clique no botão `Editar Cadastro` no canto superior direito
              e preencha os dados obrigatórios!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleCadastro()}
              color="primary"
              style={{ outline: 'none' }}
            >
              Continuar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={relatorio}
          onClose={() => setRelatorio(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Geração do relatório Word
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Desculpe mas ocorreu um erro durante a geração do relatório, por
              favor entre em contato com a equipe de suporte para mais
              informações!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setRelatorio(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={sucess}
          onClose={() => setSucess(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Geração do relatório Word
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Seu relatório foi gerado com sucesso! Aguarde este icone aparecer
              na linha do seu PTAM: <MdFileDownload size={16} color="#2e656a" />{' '}
              e efetue o download do documento!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setSucess(false)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={clone.cl}
          onClose={() => setClone({ cl: false, id: '' })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Clonar PTAM</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirma a ação de clonar o PTAM Nº {clone.id}?{' '}
              <b>Observação: </b>
              Todos os dados do PTAM origem serão clonados, confira os mesmos e
              altere os que forem necessários!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setClone({ cl: false, id: '' })}
              color="primary"
              style={{ outline: 'none' }}
            >
              Fechar
            </Button>
            <Button
              onClick={() => clonePtam(clone.id)}
              color="primary"
              style={{ outline: 'none' }}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={clonado.clone}
          onClose={() => setClonado({ clone: false, id: '' })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">PTAM Clonado!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              PTAM clonado com sucesso! Verifique na sua lista o novo PTAM
              numero {clonado.id}!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setClonado({ clone: false, id: '' })}
              color="primary"
              style={{ outline: 'none' }}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {open ? (
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {user.expired ? 'Assinatura Expirada !!' : 'Bem Vindo'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description" />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Fechar
              </Button>
              <Button onClick={() => handleSignature()} color="primary">
                {user.expired ? 'Renovar' : null}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      {delet.del ? (
        <div>
          <Dialog
            open={delet.del}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setDelet({ del: false, id: '' })}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              Exclusão do PTAM Nº {delet.id}?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {delet_text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setDelet({ del: false, id: '' })}
                color="primary"
              >
                Cancelar
              </Button>
              <Button onClick={() => deletePtam(delet.id)} color="primary">
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      {expired.exp ? (
        <div>
          <Dialog
            open={expired.exp}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setExpired({ exp: false, id: '' })}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              PTAM Nº {expired.id} expirado!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {expired_text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setExpired({ exp: false, id: '' })}
                color="primary"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      {support ? (
        <div>
          <Dialog
            open={support}
            onClose={() => setSupport(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              Nossos canais de suporte
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <SuportItems
                  href="https://api.whatsapp.com/send?phone=19999998221&text=Gostaria de ajuda para utilizar o portal Avalie Fácil."
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsapp size={32} color="#34af23" />
                  <strong>(19) 99999-8221</strong>
                </SuportItems>
                <SuportItems
                  href="https://www.youtube.com/channel/UChWXoFzHL_B3-m17gkm96vw/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube size={32} color="#C4302b" />
                  <strong>Avalie Fácil</strong>
                </SuportItems>
                <SuportItems href="mailto: avaliefacil@gmail.com">
                  <MdEmail size={32} />
                  <strong> avaliefacil@gmail.com</strong>
                </SuportItems>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setSupport(false)}
                color="primary"
                style={{ outline: 'none' }}
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      <Header>
        <div>
          <FaBuffer size={20} color="#fa923f" />
          <h1>Lista de Ptams cadastrados</h1>
        </div>
      </Header>
      <div>
        <FormControl className={classes.margin}>
          <InputLabel htmlFor="input-with-icon-adornment1">
            Pesquise pelo Solicitante
          </InputLabel>
          <Input
            id="input-with-icon-adornment1"
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle size={20} />
              </InputAdornment>
            }
            value={search.solicitante}
            onChange={e =>
              setSearch({ ...search, solicitante: e.target.value })
            }
          />
        </FormControl>
        <FormControl className={classes.margin}>
          <InputLabel htmlFor="input-with-icon-adornment2">
            Pesquise pelo nº do ptam
          </InputLabel>
          <Input
            id="input-with-icon-adornment2"
            startAdornment={
              <InputAdornment position="start">
                <FaBuffer size={20} />
              </InputAdornment>
            }
            value={search.ptam}
            onChange={e => setSearch({ ...search, ptam: e.target.value })}
          />
        </FormControl>
        <Fab
          color="default"
          aria-label="seacr"
          className={classes.fab}
          size="small"
          onClick={() => handleSearch()}
        >
          <SeachIcon size="small" />
        </Fab>
        <div className="support">
          <button type="button" onClick={() => setSupport(true)}>
            <FcCustomerSupport size={24} color="#fff" />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <strong>Precisa de Ajuda?</strong>
          </button>
        </div>
      </div>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Nº Ptam</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Tipo Ptam</StyledTableCell>
              <StyledTableCell align="left">Solicitante</StyledTableCell>
              <StyledTableCell align="left">E-mail</StyledTableCell>
              <StyledTableCell align="center">Criado em</StyledTableCell>
              <StyledTableCell align="center">Expira em</StyledTableCell>
              <StyledTableCell align="right">Baixar Ptam</StyledTableCell>
              <StyledTableCell align="right">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ptams.map(ptam => (
              <StyledTableRow key={ptam.id}>
                <StyledTableCell
                  align="center"
                  onClick={() => handleClick(ptam)}
                >
                  {ptam.id}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClick(ptam)}>
                  {ptam.mobile ? (
                    <Mobile />
                  ) : ptam.status && handleCheckExpires(ptam) ? (
                    <Concluido />
                  ) : !handleCheckExpires(ptam) ? (
                    <Expired />
                  ) : (
                    <Pendente />
                  )}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClick(ptam)}>
                  {ptam.individual ? <Individual /> : <Assinatura />}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClick(ptam)}>
                  {ptam.requester ? ptam.requester.nome : 'Não cadastrado'}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClick(ptam)}>
                  {ptam.requester ? ptam.requester.email : 'Não cadastrado'}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => handleClick(ptam)}
                >
                  {ptam.emissao}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => handleClick(ptam)}
                >
                  {ptam.expiracao}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <BtnStyle>
                    {ptam.status && handleCheckAccess(ptam) && (
                      <button
                        align="center"
                        type="button"
                        title="Gerar Ptam"
                        onClick={() => printPtam(ptam.id)}
                      >
                        <div>
                          <FiDownloadCloud size={20} color="#0d2c5b" />
                        </div>
                      </button>
                    )}
                  </BtnStyle>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <BtnStyle>
                    <>
                      {handleCloneAccess(ptam) && (
                        <button
                          type="button"
                          title="Clonar Ptam"
                          onClick={() => setClone({ cl: true, id: ptam.id })}
                        >
                          <div>
                            <FaClone size={20} color="#353839" />
                          </div>
                        </button>
                      )}
                    </>

                    {handleCheckAccess(ptam) && (
                      <button
                        type="button"
                        title="editar"
                        onClick={() => editPtam(ptam)}
                      >
                        <FaEdit size={20} color="#fa923f" />
                      </button>
                    )}

                    {!user.expired && !ptam.individual && !ptam.mobile && (
                      <button
                        type="button"
                        title="deletar"
                        onClick={() => {
                          setDelet({ del: true, id: ptam.id });
                        }}
                      >
                        <MdDeleteSweep size={20} color="#F93636" />
                      </button>
                    )}
                  </BtnStyle>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <PageActions>
        <div>
          <button
            type="button"
            onClick={() => handlePage('back')}
            disabled={page < 2}
          >
            <FaAngleDoubleLeft size={24} color="#fff" />
            <strong>
              <span>Voltar</span>
            </strong>
          </button>
          <button type="button" onClick={() => handlePage('next')}>
            <strong>
              <span>Próximo</span>
            </strong>
            <FaAngleDoubleRight size={24} color="#fff" />
          </button>
        </div>
      </PageActions>
    </Container>
  );
}
